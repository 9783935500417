import { Link } from "gatsby"
import PropTypes from "prop-types"
import Image from "./image"
import React from "react"
import Header from './header'
import styled from "styled-components"

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 10px auto;
`

const Title = styled.h2`
  
`

const PrimaryAction = styled(Link)`
  margin: 25px 0 30px;
  background: #3b3b38;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;
`

const ImageContainer = styled.div`
  margin-right: 10px;
`

const Card = ({ title, description, image, actionLink, actionName }) => (
  <CardContainer>
    <ImageContainer>
      <Image />
    </ImageContainer>
    <div>
      <Title>{title}</Title>
      <p>{description}</p>
      <PrimaryAction to={actionLink}>{actionName}</PrimaryAction>
    </div>
  </CardContainer>
)

Header.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  actionLink: PropTypes.string,
  actionName: PropTypes.string,
}

Header.defaultProps = {
  title: "",
  description: "",
  image: "",
  actionLink: "",
  actionName: "",
}

export default Card
