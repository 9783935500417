import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import SEO from "../components/seo"
import Carousel from 'nuka-carousel';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`brandsite`, `tempalate`, `gatsby`]} />
    <Carousel>
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide1" alt="slide 1" />
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide2" alt="slide 1" />
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide3" alt="slide 1" />
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide4" alt="slide 1" />
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide5" alt="slide 1" />
      <img src="http://placehold.it/700x350/ffffff/3b3b38/&text=slide6" alt="slide 1" />
    </Carousel>
    <Card title={"Product A"} description={"Description of product A with additional information."}  actionLink={"/"} actionName={"Learn more"}/>
    <Card title={"Product B"} description={"Description of product B with additional information."}  actionLink={"/"} actionName={"View Products"}/>
  </Layout>
)

export default IndexPage
