import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const MenuItem = styled.li`
 list-style-type: 'none';
  padding: '.2rem';
`
const MenuItemLink = styled(Link)`
   color: white;
   font-size: .7rem
`

const Menu = ({ menuLinks }) => (
  <nav style={{ display: 'flex', flex: 1}}>
    {
      menuLinks.map(link =>
        <MenuItem key={link.name} style={{ }}>
          <MenuItemLink to={link.link}>{link.name}</MenuItemLink>
        </MenuItem>)
    }
  </nav>
)

export default Menu
