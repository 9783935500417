import React from "react"
import styled from "styled-components"

const FooterContainer = styled.footer`
  font-size: 12px;
  line-height: 20px;
`;

const Footer = () => (
  <FooterContainer>
    <div><p>Copyright © 2019. All Rights Reserved.</p></div>
  </FooterContainer>
)

export default Footer
