import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "./menu"
import styled from "styled-components"

const HeaderContainer = styled.header`
  background: black;
  margin-bottom: 1.45rem;
  border-bottom: 0.2rem solid #79bf41;
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  maxWidth: 960;
  padding: 1.45rem 1.0875rem;
  display: grid;
   grid-template-columns: 1fr 5fr;
`;

const SiteTitle = styled.h1`
  margin: 0 0.2rem;
`;

const SiteTitleLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

const Header = ({ siteTitle, menuLinks }) => (
  <HeaderContainer>
    <InnerContainer>
      <SiteTitle>
        <SiteTitleLink to="/">
          {siteTitle}
        </SiteTitleLink>
      </SiteTitle>
      <Menu menuLinks={menuLinks}/>
    </InnerContainer>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
